// 延保产品录单、 代扣签署
import request from '@/utils/request'

const extentdedApi = {
  // productList: '/insurance/product/getUserProduct',
  submitPolicy: '/insurance/installmentRepay/submitPolicy', // 第三方延保提交保单信息
  getPolicy: '/insurance/installmentRepay/getPolicy',
  payPolicy: '/insurance/installmentRepay/payPolicy',
  getSelect: '/basics/dictData/getSelect', // 通过字典数据获取服务商数据
  renderForm: '/insurance/insurance/renderForm', // 返回产品列表及用户信息
  submitMultPolicy: '/insurance/installmentRepay/submitMultPolicy', // 多产品分期下单
  getMultPolicy: '/insurance/installmentRepay/getMultPolicy', // 获取多产品订单信息
  getBillingPaymentType: '/basics/userSetting/billingPaymentType' // 获取用户设置的支付方式
}
// export function getProductList(param) {
//   return request.get(extentdedApi.productList, param)
// }
export function renderForm(param) {
  return request.get(extentdedApi.renderForm, param)
}
export function createOrder(param) {
  return request.post(extentdedApi.submitPolicy, param)
}

export function getPolicy(param) {
  return request.get(extentdedApi.getPolicy, param)
}

export function payPolicy(param) {
  return request.post(extentdedApi.payPolicy, param)
}
// 获取服务商数据
export function getSysData(param) {
  return request.get(extentdedApi.getSelect, param)
}

export function submitMultPolicy(param) {
  return request.post(extentdedApi.submitMultPolicy, param)
}

export function getMultPolicy(param) {
  return request.get(extentdedApi.getMultPolicy, param)
}

export function getBillingPaymentType(param) {
  return request.get(extentdedApi.getBillingPaymentType, param)
}