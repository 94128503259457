<template>
  <van-form @submit="onSubmit" class="form" @failed="onFailed">
    <van-field readonly v-model="type" name="type" label="服务类型" input-align="right" />
    <van-field readonly type="textarea" rows="1" autosize v-model="productName" name="product" label="服务名称" input-align="right" />
    <van-field name="franchiserCode" readonly v-model="franchiserCode" label="经销商" input-align="right" :rules="[{ required: true, message: '请选择经销商' }]">
      <!-- <template #input>
        <van-dropdown-menu>
          <van-dropdown-item v-model="franchiserCode" :options="franchiser" />
        </van-dropdown-menu>
      </template> -->
    </van-field>
    <van-field placeholder="请填写客户姓名" v-model="customerName" input-align="right" name="customerName" label="客户姓名" :rules="[{ required: true, message: '请填写客户姓名' }]" />
    <van-field placeholder="请填写手机号" v-model="contactPhone" input-align="right" name="contactPhone" type="tel" label="手机号码" :rules="[{ required: true, pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: '手机号格式错误' }]" />
    <van-field placeholder="请填写身份证" v-model="certificateNo" input-align="right" name="certificateNo" label="身份证" :rules="[{ required: true, message: '身份证格式错误' }]" />
    <!-- <van-field name="servicePeriod" :value="servicePeriod" label="服务期限" input-align="right">
      <template #input>
        <van-dropdown-menu>
          <van-dropdown-item v-model="servicePeriod" :options="servicePeriodList" />
        </van-dropdown-menu>
      </template>
    </van-field> -->
    <van-field placeholder="请填写服务价格" v-model="servicePrice" input-align="right" type="number" name="servicePrice" label="服务价格" :rules="[{ required: true, pattern: /^([1-9]\d{0,15}(\.\d{1,2})?$)/, message: '请填写不低于2元的服务价格' }]" />
    <van-field placeholder="请选择付款方式" :value="installment" input-align="right" name="installment" type="digit" label="付款方式" :rules="[{ required: true, message: '请选择付款方式' }]">
      <template #input>
        <van-dropdown-menu>
          <van-dropdown-item v-model="installment" :options="columns" />
        </van-dropdown-menu>
      </template>
    </van-field>
    <van-field v-show="installment === 1" placeholder="请填首付款金额" v-model="firstPayment" input-align="right" type="number" name="firstPayment" label="首付款金额" :rules="[{ required: true, pattern: /^([1-9]\d{0,15}(\.\d{1,2})?$)/, message: '请填写大于1元的首付款金额' }]" />
    <div class="row justify-around btns">
      <van-button round block type="info" native-type="submit">生成收款二维码</van-button>
    </div>
  </van-form>
</template>
<script>
  import storage from '../../utils/storage'
  import { submitMultPolicy } from '@/api/extendedWarranty'
  export default {
    data() {
      return {
        type: '延保产品',
        productName: this.$route.query.productName,
        productIds: this.$route.query.productId,
        customerName: '',
        contactPhone: '',
        certificateNo: '',
        servicePrice: '',
        showPicker: false,
        servicePeriod: "7",
        installment: 1,
        firstPayment: '1',
        franchiserCode: '',
        columns: [],
        selectProduct: []
        // franchiser: []
      }
    },
    async created() {
      this.franchiserCode = this.$route.query.storeName || ''
      const obj = storage.getItem('orderInfo')
      this.selectProduct = storage.getItem('selectProduct')
      const queryUserId = this.$route.query.id
      this.productIds = this.selectProduct.map(it => it.id).join(',')
      this.productName = this.selectProduct.map(it => it.productName).join(',')
      const hasYB = this.selectProduct.some(it => it.productType === 4)
      this.type = (hasYB ? '延保产品' : '综合服务') + ((hasYB && this.selectProduct.length > 1) ? ' + 综合服务' : '')
      this.columns = storage.getItem('PayType')
      this.installment = this.columns.length > 1 ? 1 : this.columns[0].value

      if (obj && obj.userId === queryUserId && obj.productIds === this.productIds) {
        this.customerName = obj.customerName
        this.contactPhone = obj.contactPhone
        this.certificateNo = obj.certificateNo  
        this.servicePrice = obj.servicePrice
        this.firstPayment = obj.firstPayment
        this.franchiserCode = obj.franchiserCode
      } else {
        storage.clearItem('orderInfo')
      }
    },
    computed: {
      servicePeriodList() {
        const services = storage.getItem('changeService')
        if (!services || services.length < 1) return []
        const repText = JSON.stringify(services).replace('label', 'text')
        return JSON.parse(repText)
      }
    },
    methods: {
      async onSubmit(values) {
        values.userId = this.$route.query.id
        values.userName = this.$route.query.userName
        values.productName = this.productName
        values.productIds = this.productIds
        values.storeId = this.$route.query.storeId

        if (values.servicePeriod) values.servicePeriod = this.servicePeriod
        storage.clearItem('orderInfo')
        storage.setItem('orderInfo', values)
        const resp = await submitMultPolicy(values)
        if (resp.success) {
          storage.setItem('orderInfo', Object.assign(values, { insuranceNo: resp.content.insuranceNo }))
          this.$router.push({ path: '/extended/confirm_pay', query: { insuranceNo: resp.content.insuranceNo} })
        } else {
          this.$toast.fail(resp.respMag)
        }
      },
      onFailed(err) {
        console.log(err, 'err ------------')
      }
    }
  }
</script>
<style scoped>
  .form {
    padding: 20px 10px;
  }

  .form>>>.van-dropdown-menu__bar {
    height: auto;
    box-shadow: none;
  }

  .title {
    width: 100%;
    text-align: center;
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 30px;
  }

  .btns {
    width: 100%;
    padding: 20px 40px 0;
  }
</style>