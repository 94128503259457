const storage = {
  setItem: (name, value) => {
    window.sessionStorage.setItem(name, JSON.stringify(value))
  },
  getItem: (name) => {
    const res = window.sessionStorage.getItem(name)
    return JSON.parse(res)
  },
  clearItem: (name) => {
    window.sessionStorage.removeItem(name)
  }
}

export default storage